import * as React from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { useForm, useWindowDimensions } from "../hooks";
import { isRequired } from "../hooks/useForm";
import { useContext } from "react";
import { MisureColliContext } from "../contexts";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { Qrcode } from "../components";
import CloseIcon from "@mui/icons-material/Close";
import {
  alpha,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  InputAdornment,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import BottomAppBarTwo from "../components/BottomAppBarTwo";
import styled from "@emotion/styled";
import { Send } from "@mui/icons-material";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  background: alpha(theme.palette.warning.main, 0.40),
  "&:hover": {
    backgroundColor: alpha(theme.palette.warning.main, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: 15,
  fontWeight: "bold",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const MagRilevaColli = () => {
  let navigate = useNavigate();
  let dimension = useWindowDimensions();

  const inputRef = React.useRef();
  const [openScanner, setOpenScanner] = React.useState(false);
  const [sound, setSound] = React.useState("success");
  const [ricerca, setRicerca] = React.useState("");

  /*  const [photo, setPhoto] = React.useState(false); */

  const validations = [
    ({ code }) => isRequired(code) || { code: "*Barcode obbligatorio" },
  ];

  const {
    values: barcodeForm,
    changeHandler,
    touched,
    errors,
    isValid,
  } = useForm(
    {
      code: "",
    },
    validations
  );

  const onkeyEnter = (event) => {
    if (event === 13) {
      if (barcodeForm.code && isValid) {
        getBarcodeInfo(barcodeForm.code, navigate);
      }
    }
  };

  const onkeyEnter2 = (event, e) => {
    /* e.preventDefault(); */
    if (event === 13) {
      if (ricerca) {
        ricercaSpedizione(ricerca);
      }
    }
  };

  const {
    state: { search, columns },
    getBarcodeInfo,
    ricercaSpedizione,
  } = useContext(MisureColliContext);

  React.useEffect(() => {
    setTimeout(() => {
      inputRef.current.focus();
    }, 100);
  }, []);

  /* React.useEffect(() => {
    if (barcode !== null) {
      changeHandler("code", barcode);
      getBarcodeInfo(barcode, navigate);
      setSound("error");
    }
  }, [barcode]); */

  const sendSearch = (e) => {
    setRicerca(e.target.value);
    setTimeout(() => {
      ricercaSpedizione(e.target.value);
    }, 100);
  };

  /* const showPhoto = () => {
    setPhoto(!photo);
  };

  const OpenScannerPy = () => {
    setPhoto(!photo);
    changeHandler("code", "");
  };
 */
  return (
    <>
      <Stack direction={"row"} spacing={2} m={2}>
        <TextField
          id="outlined-basic"
          label="Barcode"
          variant="outlined"
          placeholder="Inserire il barcode"
          fullWidth
          autoFocus
          autoComplete="off"
          inputRef={inputRef}
          onKeyPress={(e) => onkeyEnter(e.charCode)}
          onChange={(e) => changeHandler("code", e.target.value)}
          error={Boolean(touched.code && errors.code)}
          //helperText={touched.code && errors.code}
          value={barcodeForm.code || ""}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  color="primary"
                  sx={{ p: "10px" }}
                  aria-label="send"
                  onClick={()=>getBarcodeInfo(barcodeForm.code, navigate)}
                >
                  <Send />

                </IconButton>
                <IconButton
                  color="primary"
                  sx={{ p: "10px" }}
                  aria-label="directions"
                  onClick={
                    () => setOpenScanner(!openScanner) /* OpenScannerPy() */
                  }
                >
                  <QrCodeScannerIcon />
                  
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <Stack spacing={2} mt={2}>
        {search.length > 0 ? (
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 500 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns?.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {search.map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.PrgInterno}
                        onClick={() => getBarcodeInfo(row.barcode, navigate)}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        ) : (
          <></>
        )}
      </Stack>
      <Stack>
        {/*  componente da usare con variabile photo per aprire la modale, con api che punta nel contesto MisureColliContext, con relativo metodo e variabile barcode che si aggiorna, usare solo se e' attivo il server python
        <ModalsView
          component={
            <ScannerPython
              dimension={dimension}
              closeModalPhoto={() => showPhoto()}
              openScanner={photo}
              dataBarcode={barcodeForm.code}
            />
          }
          openModal={photo}
          closeModal={() => showPhoto()}
          title={""}
        /> */}

        {sound === "error" ? (
          <audio
            autoPlay
            src={require(sound === "success"
              ? "../positive.wav"
              : "../negative.mp3")}
          />
        ) : (
          <></>
        )}
        <Dialog open={openScanner} fullWidth keepMounted>
          <DialogActions>
            <IconButton
              aria-label="delete"
              color="error"
              onClick={() => {
                setOpenScanner(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogActions>
          <DialogContent>
            <Qrcode
              success={(value) => {
                setOpenScanner(false);
                changeHandler("code", value);
                getBarcodeInfo(value, navigate);
                setSound("error");
              }}
              start={openScanner}
              dimension={dimension}
            />
          </DialogContent>
        </Dialog>

        <BottomAppBarTwo
          child={<Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Cerca destinatario..."
                //inputProps={{ "aria-label": "search" }}
                value={ricerca || ""}
                onChange={(e) => {
                  sendSearch(e);
                }}
                onKeyPress={(e) => onkeyEnter2(e.charCode, e)}
              />
            </Search>
          }
        />
      </Stack>
    </>
  );
};

export default MagRilevaColli;
