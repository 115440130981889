import createDataContext from "./createContext";
import { BaseService, SpedizioniService } from "../api";
import { useUrlClient } from "../hooks";
import { sendNotification } from "../hooks/useNotification";
import { ErrorTitle } from "../components";

const COLLI_INSERT = "colli_insert";
const EPAL_INSERT = "epal_insert";
const SPEDIZIONE_MD = "spedizione_md";
const RICERCA = "ricerca";
const COLONNE = "colonne";
const BARCODE = "barcode";

const INITIAL_STATE = {
  colli: null,
  epal: null,
  palette: null,
  spedizione: null,
  search: [],
  columns: [],
  barcode: null,
};

const misureColliReducer = (state, action) => {
  switch (action.type) {
    case COLLI_INSERT: {
      return { ...state, colli: action.payload };
    }
    case EPAL_INSERT: {
      return { ...state, epal: action.payload };
    }
    case SPEDIZIONE_MD: {
      return { ...state, spedizione: action.payload };
    }
    case RICERCA: {
      return { ...state, search: action.payload };
    }
    case COLONNE: {
      return { ...state, columns: action.payload };
    }
    case BARCODE: {
      return { ...state, barcode: action.payload };
    }
    default: {
      return state;
    }
  }
};

const setColli = (dispatch) => async (colli) => {
  dispatch({ type: COLLI_INSERT, payload: colli });
};
const setEpal = (dispatch) => async (epal) => {
  dispatch({ type: EPAL_INSERT, payload: epal });
};

const getBarcodeInfo = (dispatch) => async (code, navigate) => {
  const response = await SpedizioniService.get(
    `${(await useUrlClient()).baseUrl}/spedizioni/misure-colli/barcode/${code}`
  );
  if (response.data) {
    navigate(`/misure-colli/${code}`);
    dispatch({ type: SPEDIZIONE_MD, payload: response.data });
  } else {
    navigate(`/home`);
  }
};

const getRefreshInfoBarcode = (dispatch) => async (code) => {
  const response = await SpedizioniService.get(
    `${(await useUrlClient()).baseUrl}/spedizioni/misure-colli/barcode/${code}`
  );
  if (response.data) {
    dispatch({ type: SPEDIZIONE_MD, payload: response.data });
  }
};

const postMisureColli =
  (dispatch) => async (prgSpedizione, spedMerce, navigate, epal) => {
    const response = await SpedizioniService.post(
      `${(await useUrlClient()).baseUrl}/spedizioni/misure-colli`,
      {
        PrgSpedizione: prgSpedizione,
        epal: epal,
        spedmerce: spedMerce,
      }
    );
    if (response.data) {
      sendNotification(
        <ErrorTitle service={"Success!"} sound={"success"} />,
        "Dati caricati!",
        "success",
        5
      );
      navigate("/rileva-colli");
    }
  };

const sendPhoto = (dispatch) => async (photo, PrgSpedizione) => {
  const response = await SpedizioniService.post(
    `${(await useUrlClient()).baseUrl}/spedizioni/allegafoto`,
    {
      PrgSpedizione: PrgSpedizione,
      imagebase64: photo,
    }
  );
  if (response.data) {
    sendNotification(
      <ErrorTitle service={"Success!"} sound={"success"} />,
      response.data.Message,
      "success",
      5
    );
  }
};

const sendNote = (dispatch) => async (note, PrgSpedizione) => {
  const response = await SpedizioniService.put(
    `${
      (
        await useUrlClient()
      ).baseUrl
    }/spedizioni/note-magazzino/${PrgSpedizione}`,
    {
      NotaMagazzino_Spedizione: note,
    }
  );
  if (response.data) {
    sendNotification(
      <ErrorTitle service={"Success!"} sound={"success"} />,
      "Note Inserite",
      "success",
      5
    );
  }
};

const barcodeScan = (dispatch) => async (img) => {
  const response = await BaseService.post(
    "https://3469-93-42-69-165.eu.ngrok.io/scan",
    {
      img: img,
    }
  );
  if (response.data) {
    //alert('response.data barcode: ', response.data);
    dispatch({ type: BARCODE, payload: response.data.text_barcode });
    //return response.data.text_barcode
    /*  sendNotification(
      <ErrorTitle service={"Success!"} sound={"success"} />,
      "",
      "success",
      5
    ); */
  }
};

const ricercaSpedizione = (dispatch) => async (destinatario) => {
  const response = await SpedizioniService.get(
    `${(await useUrlClient()).baseUrl}/spedizioni/misure-colli/ricerca?destinatario=${destinatario}`,
  );
  if (response.data) {
    dispatch({ type: RICERCA, payload: response.data.data });
    dispatch({ type: COLONNE, payload: response.data.columns });
  }
};

export const { Provider, Context } = createDataContext(
  misureColliReducer,
  {
    setColli,
    setEpal,
    getBarcodeInfo,
    getRefreshInfoBarcode,
    sendPhoto,
    postMisureColli,
    sendNote,
    ricercaSpedizione,
    barcodeScan,
  }, // actions
  INITIAL_STATE // initial state
);
