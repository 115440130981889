import {
    ArrowUpward,
    DesktopAccessDisabledTwoTone,
    More,
    Search,
    Send,
  } from "@mui/icons-material";
  import {
    Alert,
    AppBar,
    Fab,
    Grid,
    IconButton,
    InputAdornment,
    Slide,
    Stack,
    TextField,
    Toolbar,
    Zoom,
  } from "@mui/material";
  import PropTypes from "prop-types";
  import React from "react";
  import { useContext } from "react";
  import { useEffect } from "react";
  import { useParams } from "react-router-dom";
  import { CaricoMerceContext } from "../contexts";
  import { useForm } from "../hooks";
  import ReactMarkdown from "react-markdown";
  import rehypeRaw from "rehype-raw";
  import { Box } from "@mui/system";
  import { TableRowHtml } from "../components";
  
  function HideOnScroll(props) {
    const { children, viewMenu } = props;
  
    return (
      <Slide appear={false} direction="up" in={viewMenu}>
        {children}
      </Slide>
    );
  }
  
  HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
  };
  
  
  const MagControlloColliQualitaDettaglio = (props) => {
    const { idColloQualita } = useParams();
   /*  const [customCol, setCustomCol] = React.useState([]); */
    const [viewMenu, setViewMenu] = React.useState(true);
    const inputRef = React.useRef();
  
    const {
      state: { columns, viaggio, infoViaggio },
      //getPartenzaDistribuzioneData,
      getControlloColloData,
      postControlloColloData
      //postPartenzaDistribuzione,
    } = useContext(CaricoMerceContext);
  
    useEffect(() => {
      if (idColloQualita) {
        getControlloColloData(idColloQualita);
      }
    }, []);
  
   /*  function getWindowDimensions() {
      const { innerWidth: width, innerHeight: height } = window;
      return {
        width,
        height,
      };
    } */
  
    const validations = [];
    const {
      values: posizioneForm,
      changeHandler,
      setValues,
    } = useForm(
      {
        RiferimPaletta: "",
      },
      validations
    );
  
    /* React.useEffect(() => {
      let colonneDaModificare = [];
      columns.map((res, i) => {
        colonneDaModificare = [
          ...colonneDaModificare,
          {
            field: res.id,
            headerName: res.label,
            minWidth: res.minWidth,
          },
        ];
      });
      setCustomCol(colonneDaModificare);
    }, [columns]); */
  
    const onkeyEnter = (event) => {
      if (event === 13) {
        postControlloColloData(
            idColloQualita,
            idColloQualita,
          posizioneForm.RiferimPaletta
        );
        setValues({
          RiferimPaletta: "",
        });
        setTimeout(() => {
          inputRef.current.focus();
        }, 100);
      }
    };
  
    const saveChange = () => {
        postControlloColloData(
        idColloQualita,
        idColloQualita,
        posizioneForm.RiferimPaletta
      );
      setValues({
        RiferimPaletta: "",
      });
      setTimeout(() => {
        inputRef.current.focus();
      }, 100);
    };
  
    /* function CustomFooterStatusComponent() {
      return <></>;
    } */
  
    return (
      <Grid container spacing={2} sx={{position:"fixed"}}>
        <Grid item xs={12} md={6} lg={6} m={1}>
          <Alert severity="info" component="span">
            {
              <ReactMarkdown
                children={
                  infoViaggio?.displaytext
                    ? infoViaggio.displaytext.toString()
                    : " "
                }
                rehypePlugins={[rehypeRaw]}
              ></ReactMarkdown>
            }{" "}
          </Alert>
          <Stack direction={"column"} spacing={2} m={1}>
            <TextField
              id="outlined-basic"
              label="Segnacollo"
              variant="outlined"
              placeholder="Inserire il segnacollo"
              fullWidth
              autoFocus
              autoComplete="off"
              inputRef={inputRef}
              onChange={(e) => changeHandler("RiferimPaletta", e.target.value)}
              value={posizioneForm.RiferimPaletta || ""}
              onKeyPress={(e) => onkeyEnter(e.charCode)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      variant="contained"
                      color="secondary"
                      onClick={() => saveChange()}
                    >
                      <Send />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {/* <Alert severity="warning" component="span">
              {
                <ReactMarkdown
                  children={
                    infoViaggio?.summarytext
                      ? infoViaggio.summarytext.toString()
                      : " "
                  }
                  rehypePlugins={[rehypeRaw]}
                ></ReactMarkdown>
              }
            </Alert> */}
          </Stack>
        </Grid>
        <Grid item xs={12} md={12} lg={12} m={1}>
          {columns?.length > 0 ? (
            <TableRowHtml customCol={columns} rows={viaggio}/>
          ) : (
            <></>
          )}
          <Zoom in={!viewMenu}>
            <Fab
              onClick={() => setViewMenu(!viewMenu)}
              variant="extended"
              size={"small"}
              color="primary"
              sx={{
                position: "absolute",
                bottom: 0,
                margin: "0 auto",
                left: "35%",
              }}
            >
              <ArrowUpward sx={{ mr: 1 }} />
              Mostra funzioni
            </Fab>
          </Zoom>
          <HideOnScroll {...props} viewMenu={viewMenu}>
            <AppBar
              position="fixed"
              color="primary"
              sx={{ top: "auto", bottom: 0 }}
            >
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={() => setViewMenu(!viewMenu)}
                >
                  <DesktopAccessDisabledTwoTone />
                </IconButton>
                <Box sx={{ flexGrow: 1 }} />
                <IconButton color="inherit">
                  <Search />
                </IconButton>
                <IconButton color="inherit">
                  <More />
                </IconButton>
              </Toolbar>
            </AppBar>
          </HideOnScroll>
        </Grid>
      </Grid>
    );
  };
  
  export default MagControlloColliQualitaDettaglio;
  