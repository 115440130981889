import React from "react";
import {
  AirlineStopsTwoTone,
  CropFreeTwoTone,
  DepartureBoard,
  DoNotStepTwoTone,
  ListAltSharp,
  ListAltTwoTone,
  LocalShippingTwoTone,
  LocationOnTwoTone,
  MenuBookTwoTone,
  PointOfSale,
  SearchTwoTone,
  SettingsPowerTwoTone,
  StartTwoTone,
  TravelExploreTwoTone,
  WarehouseTwoTone,
  WrongLocationTwoTone,
} from "@mui/icons-material";

const vociMenu = [
  {
    menuName: "Rilevazione misure colli",
    icon: <LocalShippingTwoTone color="action" />,
    route: "/rileva-colli",
    title: "Rilevazione misure",
    width: "40%",
    colorIcon: "primary",
    backGround:
      "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(240,246,255,1) 0%, rgba(0,212,255,0.2947368421052632) 38%)",
    visible: true,
    idMenu: 1,
    subtitle: "Spedizioni",
  },
  {
    menuName: "Carico merce in partenza. Spunta manuale visiva.",
    icon: <AirlineStopsTwoTone color="action" />,
    route: "/carico-merce-in-uscita",
    title: "Carico merce in uscita",
    width: "30%",
    backGround:
      "linear-gradient(90deg, rgba(244,0,255,0.21052631578947367) 0%, rgba(240,246,255,0.3605263157894737) 100%)",
    visible: true,
    idMenu: 2,
    colorIcon: "warning",
    subtitle: "Viaggi",
  },
  {
    menuName: "Entrata posizionamento a magazzino",
    icon: <LocationOnTwoTone color="action" />,
    route: "/posizione-merce-mag-entrata",
    title: "Entrata posizionamento a magazzino",
    width: "30%",
    backGround:
      "linear-gradient(90deg, rgba(0,255,223,0.21052631578947367) 0%, rgba(240,246,255,0.3605263157894737) 100%)",
    visible: true,
    idMenu: 3,
    colorIcon: "secondary",
    subtitle: "Logistica",
  },
  {
    menuName: "Uscita da magazzino logistico",
    icon: <WrongLocationTwoTone color="action" />,
    route: "/posizione-merce-mag-uscita",
    title: "Uscita da magazzino logistico",
    width: "30%",
    backGround:
      "linear-gradient(90deg, rgba(0,255,223,0.21052631578947367) 0%, rgba(240,246,255,0.3605263157894737) 100%)",
    visible: true,
    idMenu: 4,
    colorIcon: "secondary",
    subtitle: "Logistica",
  },
  {
    menuName: "Inventario",
    icon: <ListAltTwoTone color="action" />,
    route: "/inventario-magazzino",
    title: "Inventario magazzino",
    width: "30%",
    backGround:
      "linear-gradient(90deg, rgba(0,255,223,0.21052631578947367) 0%, rgba(240,246,255,0.3605263157894737) 100%)",
    visible: true,
    idMenu: 5,
    colorIcon: "secondary",
    subtitle: "Logistica",
  },
  {
    menuName: "Partenza per distribuzione",
    icon: <CropFreeTwoTone color="action" />,
    route: "/partenza-distribuzione",
    title: "Barcode partenza per distribuzione",
    width: "30%",
    backGround:
      "linear-gradient(90deg, rgba(0,255,223,0.21052631578947367) 0%, rgba(240,246,255,0.3605263157894737) 100%)",
    visible: true,
    idMenu: 6,
    colorIcon: "warning",
    subtitle: "Viaggi",
  },
  {
    menuName: "Arrivo da trazione",
    icon: <DepartureBoard color="action" />,
    route: "/arrivo-da-trazione",
    title: "Barcode arrivo da trazione.",
    width: "30%",
    backGround:
      "linear-gradient(90deg, rgba(0,255,223,0.21052631578947367) 0%, rgba(240,246,255,0.3605263157894737) 100%)",
    visible: true,
    idMenu: 7,
    colorIcon: "warning",
    subtitle: "Viaggi",
  },
  {
    menuName: "Partenza per trazione",
    icon: <StartTwoTone color="action" />,
    route: "/partenza-per-trazione",
    title: "Barcode partenza per trazione",
    width: "30%",
    backGround:
      "linear-gradient(90deg, rgba(0,255,223,0.21052631578947367) 0%, rgba(240,246,255,0.3605263157894737) 100%)",
    visible: true,
    idMenu: 8,
    colorIcon: "warning",
    subtitle: "Viaggi",
  },
  {
    menuName: "Assegnare SSCC a RiferimPaletta",
    icon: <ListAltSharp color="action" />,
    route: "/assegnazione-sscc-to-rifpaletta",
    title: "Assegnare SSCC a RiferimPaletta",
    width: "30%",
    backGround:
      "linear-gradient(90deg, rgba(0,255,223,0.21052631578947367) 0%, rgba(240,246,255,0.3605263157894737) 100%)",
    visible: true,
    idMenu: 9,
    colorIcon: "secondary",
    subtitle: "Logistica",
  },
  {
    menuName: "Inventario HUB.",
    icon: <MenuBookTwoTone color="action" />,
    route: "/inventario-hub",
    title: "Inventario HUB.",
    width: "30%",
    backGround:
      "linear-gradient(90deg, rgba(0,255,223,0.21052631578947367) 0%, rgba(240,246,255,0.3605263157894737) 100%)",
    visible: true,
    idMenu: 10,
    colorIcon: "warning",
    subtitle: "Viaggi",
  },
  {
    menuName: "Cerca colli per controllo qualità",
    icon: <SearchTwoTone color="action" />,
    route: "/cerca-colli-controllo-qualita",
    title: "Cerca colli per controllo qualità.",
    width: "30%",
    backGround:
      "linear-gradient(90deg, rgba(0,255,223,0.21052631578947367) 0%, rgba(240,246,255,0.3605263157894737) 100%)",
    visible: true,
    idMenu: 12,
    colorIcon: "secondary",
    subtitle: "Logistica",
  },
];

export const menuSubheder = [
  {
    menuName: "Spedizioni",
    icon: <PointOfSale />,
    colorIcon: "primary",
    idMenu: 2,
  },
  {
    menuName: "Logistica",
    icon: <WarehouseTwoTone />,
    colorIcon: "secondary",
    idMenu: 0,
  },
  {
    menuName: "Viaggi",
    icon: <TravelExploreTwoTone />,
    colorIcon: "warning",
    idMenu: 1,
  },
  /* {
    menuName: "Magazzino",
    icon: <TravelExploreTwoTone />,
    colorIcon: "info",
    idMenu: 3,
  }, */
];

export function isMobile() {
  const { innerWidth: width } = window;
  if (width > 600) {
    return false;
  } else {
    return true;
  }
}

export const getConfig = async (params) => {
  let config = { baseUrl: "", visibleMenu: [], group: [] };
  let splitPrm = params.split("/");

  let parametro = splitPrm[2];
  //const response = await fetch(`https://configuration-irs.incontraweb.it/?developer=loris`)
  const response = await fetch(
    `https://configuration-irs.incontraweb.it/?domain=${
      parametro === "192.168.1.102:3000" ? "localhost:3000" : parametro
    }&${Math.floor(Math.random() * 3)}`
  );
  const data = await response.json();
  config.baseUrl = data.server;
  let menuChoseByClient = data.visibleMenuMagApp;
  let menuHeader = data.visibleMenuMagApp_group;

  let menu = [];
  menuChoseByClient.forEach((element) => {
    let voci = {
      ...vociMenu.find((res) => res.idMenu === element.idMenu),
      menuName: element.menuName,
      idMenuGroup: element.idMenuGroup,
    };
    menu = [...menu, voci];
  });
  let group = [];
  menuHeader.forEach((element) => {
    let voci = menuSubheder.find((res) => element.idMenu === res.idMenu);
    if(voci !== undefined){
      group = [...group, voci];
    }
  });
  config.group = group;
  config.visibleMenu = menu;
  return config;
};
