import * as React from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { useForm } from "../hooks";
import { isRequired } from "../hooks/useForm";
import { useContext } from "react";
import { InventarioContext } from "../contexts";
import { Box, Grid, IconButton, InputAdornment } from "@mui/material";
import { Search, VisibilityOffTwoTone } from "@mui/icons-material";
import { TableRowHtml } from "../components";
import { useState } from "react";
import { useEffect } from "react";

const colonnaStato = {
  field: "action",
  headerName: "Azioni"
};

const MagInventarioHub = () => {
  let navigate = useNavigate();
  const inputRef = React.useRef();
  const [columns, setColumns] = useState([]);
  const {
    state: { listaHubAll, customColumnHubAll },
    getListaInventarioHub,
    getSearchInventarioHub,
  } = useContext(InventarioContext);
  const validations = [
    ({ code }) => isRequired(code) || { code: "*Barcode obbligatorio" },
  ];

  const {
    values: barcodeForm,
    changeHandler,
    touched,
    errors,
    isValid,
  } = useForm(
    {
      code: "",
    },
    validations
  );

  const onkeyEnter = (event) => {
    if (event === 13) {
      if (barcodeForm.code && isValid) {
        getListaInventarioHub(barcodeForm.code, navigate);
      }
    }
  };

  useEffect(() => {
    let colonne = [];
    if (customColumnHubAll.length > 0) {
      customColumnHubAll.forEach((element) => {
        colonne = [
          ...colonne,
          {
            field: element.id,
            headerName: element.label,
            width: 300,
            /*   flex:1, */
          },
        ];
      });
      colonne = [colonnaStato,...colonne];
      setColumns(colonne);
    }
  }, [customColumnHubAll]);

  React.useEffect(() => {
    getSearchInventarioHub();
    setTimeout(() => {
      inputRef.current.focus();
    }, 100);
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} md={12} lg={12} m={2}>
        <TextField
          id="outlined-basic"
          label="Inventario"
          variant="outlined"
          placeholder="Inserire il barcode"
          fullWidth
          autoFocus
          inputRef={inputRef}
          onKeyPress={(e) => onkeyEnter(e.charCode)}
          onChange={(e) => changeHandler("code", e.target.value)}
          error={Boolean(touched.code && errors.code)}
          //helperText={touched.code && errors.code}
          value={barcodeForm.code || ""}
          autoComplete="off"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  color="primary"
                  disabled={!isValid}
                  onClick={() =>
                    getListaInventarioHub(barcodeForm.code, navigate)
                  }
                >
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {/* <Button
          variant="contained"
          disabled={!isValid}
          startIcon={<LocationOnIcon />}
          onClick={() => postRecordInventario(barcodeForm.code, navigate)}
        >
          Cerca
        </Button> */}
      </Grid>
      <Grid item xs={12} md={12} lg={12} p={1}>
        {columns.length > 0 ? (
            <TableRowHtml customCol={columns} rows={listaHubAll} routeAction={"/inventario-hub"} fieldAction={"PrgInventario"} />
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  );
};

export default MagInventarioHub;
