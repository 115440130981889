import * as React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ButtonAppBar, ClassicDrawer, Notifications } from "./components";
import { useNotification } from "./hooks";
import {
  MagCaricoUscita,
  MagRilevaColli,
  Login,
  MagRilevaColliMisure,
  NotFound,
  MagCaricoUscitaViaggio,
  MagPosizionamentoEntrata,
  MagPosizionamentoEntrataDettaglio,
  MagPosizionamentoUscitaDettaglio,
  MagInventario,
  MagInventarioDettaglio,
  MagArrivoTrazione,
  MagArrivoTrazioneDettaglio,
  MagPartenzaTrazione,
  MagPartenzaTrazioneDettaglio,
  MagAssegnazioneSSCCtoRifPaletta,
  MagPartenzaDistribuzione,
  MagPartenzaDistribuzioneDettaglio,
  MagInventarioHub,
  MagInventarioHubDettaglio,
  MagControlloColliQualita,
  MagControlloColliQualitaDettaglio,
} from "./pages";
import Home from "./pages/Home";
import MagPosizionamentoUscita from "./pages/MagPosizionamentoUscita";

const Main = () => {
  const [notifications, closeNotification] = useNotification();
  return (
    <>
      {notifications.length > 0 && (
        <Notifications
          notifications={notifications}
          closeNotification={closeNotification}
        />
      )}
      <Router>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Login />} />

          <Route
            path="/home"
            element={
              <>
                <ButtonAppBar></ButtonAppBar>
                <Home />
              </>
            }
          />

          <Route
            path="/rileva-colli"
            element={
              <>
                <ClassicDrawer
                  component={<MagRilevaColli />}
                  title="Lettura barcode"
                ></ClassicDrawer>
              </>
            }
          />
          <Route
            path="/misure-colli/:code"
            element={
              <>
                <ClassicDrawer
                  component={<MagRilevaColliMisure />}
                  title="Misure colli"
                ></ClassicDrawer>
              </>
            }
          />

          <Route
            path="/carico-merce-in-uscita"
            element={
              <>
                <ClassicDrawer
                  component={<MagCaricoUscita />}
                  title="Carico merce in uscita"
                ></ClassicDrawer>
              </>
            }
          />
          <Route
            path="/carico-merce-in-uscita/:idviaggio"
            element={
              <>
                <ClassicDrawer
                  component={<MagCaricoUscitaViaggio />}
                  title="Viaggio"
                ></ClassicDrawer>
              </>
            }
          />

          <Route
            path="/posizione-merce-mag-entrata"
            element={
              <>
                <ClassicDrawer
                  component={<MagPosizionamentoEntrata />}
                  title="Posizionamento merce entrata"
                ></ClassicDrawer>
              </>
            }
          />
          <Route
            path="/posizione-merce-mag-entrata/:idMerce"
            element={
              <>
                <ClassicDrawer
                  component={<MagPosizionamentoEntrataDettaglio />}
                  title="Merce in entrata"
                ></ClassicDrawer>
              </>
            }
          />

          <Route
            path="/posizione-merce-mag-uscita"
            element={
              <>
                <ClassicDrawer
                  component={<MagPosizionamentoUscita />}
                  title="Posizionamento merce uscita"
                ></ClassicDrawer>
              </>
            }
          />
          <Route
            path="/posizione-merce-mag-uscita/:idMerce"
            element={
              <>
                <ClassicDrawer
                  component={<MagPosizionamentoUscitaDettaglio />}
                  title="Merce in uscita"
                ></ClassicDrawer>
              </>
            }
          />
          <Route
            path="/inventario-magazzino"
            element={
              <>
                <ClassicDrawer
                  component={<MagInventario />}
                  title="Inventario magazzino"
                ></ClassicDrawer>
              </>
            }
          />
          <Route
            path="/inventario-magazzino/:idInventario"
            element={
              <>
                <ClassicDrawer
                  component={<MagInventarioDettaglio />}
                  title="Inventario"
                ></ClassicDrawer>
              </>
            }
          />
          <Route
            path="/partenza-distribuzione"
            element={
              <>
                <ClassicDrawer
                  component={<MagPartenzaDistribuzione />}
                  title="Partenza per distribuzione"
                ></ClassicDrawer>
              </>
            }
          />
          <Route
            path="/partenza-distribuzione/:idBarcodeViaggio"
            element={
              <>
                <ClassicDrawer
                  component={<MagPartenzaDistribuzioneDettaglio />}
                  title="Partenza per distribuzione"
                ></ClassicDrawer>
              </>
            }
          />
          <Route
            path="/arrivo-da-trazione"
            element={
              <>
                <ClassicDrawer
                  component={<MagArrivoTrazione />}
                  title="Arrivo da trazione"
                ></ClassicDrawer>
              </>
            }
          />
          <Route
            path="/arrivo-da-trazione/:idBarcodeViaggio"
            element={
              <>
                <ClassicDrawer
                  component={<MagArrivoTrazioneDettaglio />}
                  title="Arrivo da trazione"
                ></ClassicDrawer>
              </>
            }
          />
          <Route
            path="/partenza-per-trazione"
            element={
              <>
                <ClassicDrawer
                  component={<MagPartenzaTrazione />}
                  title="Partenza per trazione"
                ></ClassicDrawer>
              </>
            }
          />
          <Route
            path="/partenza-per-trazione/:idBarcodeViaggio"
            element={
              <>
                <ClassicDrawer
                  component={<MagPartenzaTrazioneDettaglio />}
                  title="Partenza per trazione"
                ></ClassicDrawer>
              </>
            }
          />
          <Route
            path="/assegnazione-sscc-to-rifpaletta"
            element={
              <>
                <ClassicDrawer
                  component={<MagAssegnazioneSSCCtoRifPaletta />}
                  title="Assegnazione SSCC"
                ></ClassicDrawer>
              </>
            }
          />
          <Route
            path="/inventario-hub"
            element={
              <>
                <ClassicDrawer
                  component={<MagInventarioHub />}
                  title="Inventario HUB"
                ></ClassicDrawer>
              </>
            }
          />
          <Route
            path="/inventario-hub/:idInventarioHub"
            element={
              <>
                <ClassicDrawer
                  component={<MagInventarioHubDettaglio />}
                  title="Inventario HUB"
                ></ClassicDrawer>
              </>
            }
          />

          <Route
            path="/cerca-colli-controllo-qualita"
            element={
              <>
                <ClassicDrawer
                  component={<MagControlloColliQualita />}
                  title="Cerca colli per controllo qualità"
                ></ClassicDrawer>
              </>
            }
          />
          <Route
            path="/cerca-colli-controllo-qualita/:idColloQualita"
            element={
              <>
                <ClassicDrawer
                  component={<MagControlloColliQualitaDettaglio />}
                  title="Controllo qualità"
                ></ClassicDrawer>
              </>
            }
          />
        </Routes>
      </Router>
    </>
  );
};

export default Main;
