import createDataContext from "./createContext";
import { BaseService } from "../api";
import { useUrlClient } from "../hooks";

const END_LOGIN = "end_login";
const LOGOUT = "logout";
const md5=require("md5");

const INITIAL_STATE = {
  user: null,
};

const authReducer = (state, action) => {
  switch (action.type) {
   
    case END_LOGIN: {
      const { user } = action.payload;
      return { ...state, user };
    }
    case LOGOUT: {
      return { ...INITIAL_STATE };
    }
  
    default: {
      return state;
    }
  }
};

const login = (dispatch) => async (pin, navigate) => {
    let password =""  
  for(let i=0; i< pin.length; i++){
      password = password +"*!"+ pin[i] + "#@"
    }
      const response = await BaseService.post(`${(await useUrlClient()).baseUrl}/login`, {
      jwtusername: pin,
      jwtpassword: md5(password)
    });
    if(response.data){
      const user = response.data;
      localStorage.setItem('token', response.data.token)
      localStorage.setItem('UserDescrizione', response.data.UserDescrizione)
      dispatch({ type: END_LOGIN, payload: { user } });
      navigate("/home")
    }
};

const logout = (dispatch) => async (navigate) => {
  navigate("/")
  /* localStorage.removeItem('token')
  localStorage.removeItem('pin') */
  localStorage.clear()
  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name);
    });
  });
  dispatch({ type: LOGOUT });
};

export const { Provider, Context } = createDataContext(
  authReducer,
  {
    login,
    logout,
  }, // actions
  INITIAL_STATE // initial state
);
