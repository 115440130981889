import createDataContext from "./createContext";
import { SpedizioniService } from "../api";
import { useUrlClient } from "../hooks";
import { sendNotification } from "../hooks/useNotification";
import { ErrorTitle } from "../components";

const MAGMOVIM_MD = "magmovim_md";
const DISPALY_TEXT = "display_text";
const CUSTOM_COLUMN = "custom_column";

const INITIAL_STATE = {
  magMovim: [],
  displayText: "",
  customColumn: [],
};

const posizioneMerceReducer = (state, action) => {
  switch (action.type) {
    case MAGMOVIM_MD: {
      return { ...state, magMovim: action.payload };
    }
    case DISPALY_TEXT: {
      return { ...state, displayText: action.payload };
    }
    case CUSTOM_COLUMN: {
      return { ...state, customColumn: action.payload };
    }
    default: {
      return state;
    }
  }
};

const getBarcodeInfo = (dispatch) => async (code, navigate) => {
  const response = await SpedizioniService.get(
    `${(await useUrlClient()).baseUrl}/magmovim/barcode/${code}?causale="ent"`
  );
  if (response.data) {
    navigate(`/posizione-merce-mag-entrata/${code}`);
    dispatch({ type: MAGMOVIM_MD, payload: response.data.magmerce });
    dispatch({ type: DISPALY_TEXT, payload: response.data.displaytext });
    dispatch({ type: CUSTOM_COLUMN, payload: response.data.columns });
  }
};

const getBarcodeInfoUscita = (dispatch) => async (code, navigate) => {
  const response = await SpedizioniService.get(
    `${(await useUrlClient()).baseUrl}/magmovim/barcode/${code}?causale="usc"`
  );
  if (response.data) {
    navigate(`/posizione-merce-mag-uscita/${code}`);
    //console.log('response.data.magmerce: ', response.data.magmerce);
    dispatch({ type: MAGMOVIM_MD, payload: response.data.magmerce });
    dispatch({ type: DISPALY_TEXT, payload: response.data.displaytext });
    dispatch({ type: CUSTOM_COLUMN, payload: response.data.columns });
  }
};

const postMagMovim = (dispatch) => async (payload, code, magMovim) => {
  const response = await SpedizioniService.post(
    `${(await useUrlClient()).baseUrl}/magmovim/ubicazione`,
    {
      PrgMovimento: magMovim[0].PrgMovimento,
      RiferimPaletta: payload.RiferimPaletta,
      Ubicazione: payload.Ubicazione,
    }
  );
  if (response.data) {
    sendNotification(
      <ErrorTitle service={"Success!"} sound={"success"} />,
      "Elemento inserito correttamente",
      "success",
      5
    );
    getRefreshInfoBarcode(dispatch)(code);
  }
};

const postUscitaMagMovim =
  (dispatch) => async (code, prgmovimento, riferimpaletta) => {
    const response = await SpedizioniService.post(
      `${
        (
          await useUrlClient()
        ).baseUrl
      }/magmovim/registra-uscita-riferimpaletta/${prgmovimento}/${riferimpaletta} `
    );
    if (response.data) {
      sendNotification(
        <ErrorTitle service={"Success!"} sound={"success"} />,
        "Ok Elemento Caricato Correttamente",
        "success",
        5
      );
    } else {
      sendNotification(
        <ErrorTitle service={"Attenzione!"} />,
        "Elemento non trovato",
        "warning",
        5
      );
    }
    getRefreshInfoBarcode(dispatch)(code);
  };

const getRefreshInfoBarcode = (dispatch) => async (code) => {
  const response = await SpedizioniService.get(
    `${(await useUrlClient()).baseUrl}/magmovim/barcode/${code}`
  );
  if (response.data) {
    dispatch({ type: MAGMOVIM_MD, payload: response.data.magmerce });
    dispatch({ type: DISPALY_TEXT, payload: response.data.displaytext });
    dispatch({ type: CUSTOM_COLUMN, payload: response.data.magmerce_columns });
  }
};

const postAssegnazioneSSCC = (dispatch) => async (payload) => {
  const response = await SpedizioniService.post(
    `${(await useUrlClient()).baseUrl}/magmovim/riferimpaletta-riferimsscc`,
    {
      RiferimPaletta: payload.RiferimPaletta,
      RiferimSSCC: payload.RiferimSSCC,
    }
  );
  if (response.data) {
    sendNotification(
      <ErrorTitle service={"Success!"} sound={"success"} />,
      "Elemento associato correttamente",
      "success",
      5
    );
  }
};

export const { Provider, Context } = createDataContext(
  posizioneMerceReducer,
  {
    getBarcodeInfo,
    getRefreshInfoBarcode,
    postMagMovim,
    postUscitaMagMovim,
    getBarcodeInfoUscita,
    postAssegnazioneSSCC
  }, // actions
  INITIAL_STATE // initial state
);
