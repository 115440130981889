import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  AppBar,
  Card,
  Grid,
  IconButton,
  ListItemAvatar,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useMenuView } from "../hooks";
import { useNavigate } from "react-router-dom";
import { DashboardTwoTone, Logout } from "@mui/icons-material";
import { AuthContext } from "../contexts";
import { useState } from "react";

/* const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
})); */

export default function ClassicDrawer(props) {
  const { component, title } = props;
  const [drawerMenu, setDrawerMenu] = useState([]);
  const [menuSubheder, setSubheder] = useState([]);

  React.useEffect(() => {
    const MenuDashboardComponent = async () => {
      let drawerMenuCustom = await useMenuView();
      setDrawerMenu(drawerMenuCustom.visibleMenu);
      let menuSubhederApi = await useMenuView();
      setSubheder(menuSubhederApi.group);
    };
    MenuDashboardComponent();
  }, []);

  let navigate = useNavigate();
  const { logout } = React.useContext(AuthContext);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const navigateTo = (text) => {
    navigate(text);
    toggleDrawer("left", false);
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem disablePadding onClick={() => navigate("/home")} mb={4}>
          <ListItemButton>
            <ListItemIcon>
              <DashboardTwoTone />
            </ListItemIcon>
            <ListItemText primary={"Home"} />
          </ListItemButton>
        </ListItem>
        {menuSubheder.map((res, i) => {
          return (
            <List
              key={i}
              dense={false}
              sx={{
                width: "100%",
                bgcolor: (theme) => theme.palette.background,
              }}
              subheader={
                <List dense={true}>
                  <Card
                    sx={{
                      border: 1,
                      borderColor: (theme) =>
                        theme.palette[res.colorIcon].light,
                      borderTopRightRadius: 5,
                      borderBottomLeftRadius: 0,
                      borderTopLeftRadius: 12,
                      borderBottomRightRadius: 20,
                      backgroundColor: (theme) =>
                        theme.palette[res.colorIcon].main,
                      width: "90%",
                      opacity: "0.8",
                    }}
                  >
                    <ListItem>
                      <ListItemText
                        primaryTypographyProps={{
                          fontSize: "16px",
                          fontWeight: "bold",
                          fontFamily: "inherit",
                        }}
                        primary={res.menuName}
                        ///secondary="Jan 9, 2014"
                      />
                      <ListItemAvatar>
                        <IconButton>{res.icon}</IconButton>
                      </ListItemAvatar>
                    </ListItem>
                  </Card>
                </List>
              }
            >
              {drawerMenu.map((text, index) => {
                if (text && text.subtitle === res.menuName) {
                  return (
                    <ListItem
                      key={index}
                      disablePadding
                      onClick={() => navigateTo(text.route)}
                    >
                      <ListItemButton>
                        <ListItemIcon>{text.icon}</ListItemIcon>
                        <ListItemText primary={text.menuName} />
                      </ListItemButton>
                    </ListItem>
                  );
                }
              })}
            </List>
          );
        })}
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment>
        <Button onClick={toggleDrawer("left", true)}>Apri</Button>
        <SwipeableDrawer
          anchor={"left"}
          open={state["left"]}
          onClose={toggleDrawer("left", false)}
          onOpen={toggleDrawer("left", true)}
        >
          {list("left")}
        </SwipeableDrawer>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="fixed">
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                variant="h6"
                edge="start"
                onClick={toggleDrawer("left", true)}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                variant="subtitle1"
                component="div"
                sx={{ flexGrow: 1, marginLeft: 5 }}
                onClick={toggleDrawer("left", true)}
              >
                {title}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <Paper
                      sx={{
                        border: 2,
                        borderColor: (theme) => theme.palette.primary.light,
                        borderRadius: 30,
                        /* borderBottomRightRadius: 30,
                    borderTopLeftRadius: 20, */
                        backgroundColor: (theme) => theme.palette.info.main,
                      }}
                    >
                      <Typography
                        fontFamily={"unset"}
                        fontSize={15}
                        color={"white"}
                        p={1}
                      >
                        {localStorage
                          .getItem("UserDescrizione")
                          .substring(0, 3)
                          .toUpperCase()}
                      </Typography>
                    </Paper>
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem>
                  <Stack direction={"row"} spacing={2}>
                    {localStorage.getItem("UserDescrizione").toUpperCase()}
                  </Stack>
                </MenuItem>
                <Divider />
                {/* <MenuItem>
                  <ListItemIcon>
                    <Settings fontSize="small" />
                  </ListItemIcon>
                  Settings
                </MenuItem> */}
                <MenuItem onClick={() => logout(navigate)}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </Toolbar>
          </AppBar>
        </Box>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12} md={12} lg={12}>
            {component}
          </Grid>
        </Grid>
      </React.Fragment>
    </div>
  );
}
