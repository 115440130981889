import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {
  Avatar,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Tooltip,
  useScrollTrigger,
} from "@mui/material";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Logout} from "@mui/icons-material";
import { useContext } from "react";
import { AuthContext } from "../contexts";
import { useEffect } from "react";
/* import { useMenuView } from "../hooks"; */

/* const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
})); */

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default function ButtonAppBar(props) {
  /*  let location = useLocation(); */
  let navigate = useNavigate();
  const { logout } = useContext(AuthContext);
  /* let menuDashboard = useMenuView(); */

/*   const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  }); */

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

useEffect(() => {
  if(localStorage.getItem("UserDescrizione") === null ||  localStorage.getItem("token") === null){
    navigate("/")
  }
}, [])


  /* const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  }; */

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed">
        {/*  <ElevationScroll {...props}> */}
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <Avatar alt="Logo" src={require("../static/logo.png")} />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            InConTra
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <Paper
                  sx={{
                    border: 2,
                    borderColor: (theme) => theme.palette.primary.light,
                    borderRadius: 30,
                    /* borderBottomRightRadius: 30,
                    borderTopLeftRadius: 20, */
                    backgroundColor: (theme) => theme.palette.info.main,
                  }}
                >
                  <Typography
                    fontFamily={"unset"}
                    fontSize={15}
                    p={1}
                    color={"white"}
                  >
                    {
                    localStorage.getItem("UserDescrizione") !== null ?  localStorage
                    .getItem("UserDescrizione")
                    ?.substring(0, 3)
                    ?.toUpperCase() : ""
                   }
                  </Typography>
                </Paper>
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem>
              <Stack direction={"row"} spacing={2}>
                    {localStorage.getItem("UserDescrizione").toUpperCase()}
                  </Stack>
            </MenuItem>
            <Divider />
            {/* <MenuItem>
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                Settings
              </MenuItem> */}
            <MenuItem onClick={() => logout(navigate)}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
        {/*  </ElevationScroll> */}
      </AppBar>
    </Box>
  );
}
