import { ErrorTitle } from "../components";
import { sendNotification } from "../hooks/useNotification";

export const setLoading = (loading) => window.dispatchEvent(new CustomEvent('change_loading', { detail: loading }));


export const errorInterceptor = (error, serviceName) => {
  if (error.response.data.statuscode === 401 || error.response.data.message ==="Authorization Required" || error.response.data.message ==="Invalid Token" ) {
    window.location.assign("/");
    sendNotification(
      <ErrorTitle service={serviceName} sound={"error"} />,
      error.response.data.message,
      "error",
      5
    );
  } else if (error.response.data.message === undefined) {
    sendNotification(
      <ErrorTitle service={serviceName} sound={"error"} />,
      "Pin Errato",
      "error",
      5
    );
  } else {
    sendNotification(
      <ErrorTitle service={serviceName} sound={"error"} />,
      error.response.data.message ? error.response.data.message : error.message,
      "error",
      5
    );
  }

};